button {
  font-family: inherit;
}

.text {
  //elements
  &__metric-count {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: $color-black;
  }

  // font sizes
&--extra-large{
  font-size: $font-size-extra-large !important;
  line-height: 49px;
}
  &--large {
    font-size: $font-size-large !important;
  }

  &--medium {
    font-size: $font-size-medium !important;
  }

  &--regular {
    font-size: $font-size-regular !important;
  }

  &--regular-alt {
    font-size: $font-size-regular-alt !important;
  }

  &--semi-small {
    font-size: $font-size-semi-small !important;
  }

  &--small {
    font-size: $font-size-small !important;
  }

  &--tiny {
    font-size: $font-size-tiny !important;
  }

  //
  &--italic{
    font-style: italic !important;
  }
  &--upper{
    text-transform: uppercase !important;
  }

  &--capital{
    text-transform: capitalize !important;
  }

  &--lower{
    text-transform: lowercase !important;
  }

  &--underline{
    text-decoration: underline !important;
  }

  //   colours
  &--primary {
    color: $color-primary !important;
  }

  &--blue {
    color: $color-blue !important;
  }

  &--secondary {
    color: $color-secondary !important;
  }


  &--success {
    color: $color-green !important;
  }

  &--ash {
    color: $color-ash !important;
  }

  
  &--grey {
    color: $color-grey !important;
  }

  &--danger {
    color: $color-danger !important;
  }
  &--black {
    color: $color-black !important;
  }

  &--deep-black {
    color: $color-deep-black !important;
  }

  &--white {
    color: $color-white !important;
  }

  //   weights

  &--300 {
    font-weight: 300 !important;
  }

  &--400 {
    font-weight: 400 !important;
  }

  &--500 {
    font-weight: 500 !important;
  }

  &--600 {
    font-weight: 600 !important;
  }

  &--bold {
    font-weight: bold !important;
  }

  &--800 {
    font-weight: 800 !important;
  }

  &--normal {
    font-weight: normal !important;
  }

  //positioning

  &--center{
    text-align: center;
  }

  &--left{
    text-align: left;
  }

  &--right{
    text-align: right;
  }

  &--justify{
    text-align: justify;
  }
  
}
