/////////////////////colours//////////////////

// $color-primary:#258903;
// $color-primary: #d62828;
$color-primary: #002E5B;
$color-primary2: #2E4E74;
$color-secondary:#F56B2A;
$color-secondary-light:#FEE9DF;
$color-ash:#828282;
$color-grey: #4F4F4F;
$color-white:#ffffff;
$color-black:#000;
$color-deep-black:#333333;
$color-blue: #2F80ED;
$color-blue-light: #56CCF2;
$color-danger:#FF1A1A;
$color-green:#6FCF97;
$color-yellow: #F2C94C;
$app-background: #FCFBFB;
$site-width: 1980px;

/////////////////////////font size////////////////////////////

$font-size-extra-large: 32px;
$font-size-large: 22px;
$font-size-medium:18px;
$font-size-regular:14px;
$font-size-regular-alt:16;
$font-size-semi-small: 12px;
$font-size-small: 10px;
$font-size-tiny:8px;


