/*basic reset*/
// @import url('https://fonts.googleapis.com/css2?family=Overpass&display=swap');
@import '../abstract/variables';


*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;

  // @media only screen and (max-width: 600px) {
  //   font-size: 50%;
  // }

}

body {
  // font-family: 'Josefin Sans', sans-serif;
  font-family: 'Karla', sans-serif;
  box-sizing: border-box;
  font-style: normal;
  line-height: 1.2;
}

a {
  // color: inherit !important;
  transition: all .2s ease-out;
  cursor: pointer;

  &:hover {
    // text-decoration: none !important;
    // color: //TODO;
  }

  &.btn:hover {
    text-decoration: none;
  }

  &:visited {
    // color: #F58634;
  }
}

.lh-16 {
  line-height: 16px;
}

p {
  margin: 0;
}

.position-relative {
  position: relative !important;
}

input,
select,
textarea {
  font-family: inherit;
  border-image: none;
  outline: none;
  background: transparent;
  font-size: $font-size-regular;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

html,
body {
  background-color: $app-background;
  margin: 0 auto;
  scroll-behavior: smooth;
  min-height: 100vh;
}


.isDisabled {
  pointer-events: none;
  opacity: .7;
}

.mt-3 {
  margin-top: 3rem !important;
}

.w-75 {
  width: 75% !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-100 {
  width: 100% !important;
}

.mt5 {
  margin-top: 50px !important;
}

.mt4 {
  margin-top: 40px !important;
}

.mb4 {
  margin-bottom: 40px !important;
}

.mt3 {
  margin-top: 30px !important;
}

.mb3 {
  margin-bottom: 30px !important;
}

.mt2 {
  margin-top: 20px !important;
}

.mb2 {
  margin-bottom: 20px !important;
}

.mt1 {
  margin-top: 10px !important;
}

.mb1 {
  margin-bottom: 10px !important;
}

.m0 {
  margin: 0 !important;
}

.table-100 {
  display: block;
  width: 100%;
  overflow: auto;
}
