@import '../abstract/variables';

.dashboard {
  // background-color: $color-white;

  &_card {
    border-radius: 10px;
    background-color: $color-white;

    &_title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: $color-deep-black;
      position: relative;
      margin: 0;
      padding-bottom: .75rem;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 1.5px;
        background-color: $color-deep-black;
      }
    }

    .loans_text:empty,
    .loans_amount:empty,
    .loans_count:empty,
    .empty:empty {
      animation: loading 1.1s infinite linear;
      background: #dedfe1;
      border-radius: 5px;
      background-image: -webkit-linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
      background-image: linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
      background-repeat: no-repeat;
    }

    .empty:empty {
      border-radius: 50% !important;
    }

    .dashboard_progress_target {
      .span:empty {
        animation: loading 1.1s infinite linear;
        background: #dedfe1;
        background-image: -webkit-linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
        background-image: linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
        background-repeat: no-repeat;
      }
    }
  }

  .loans {
    &_wrapper {
      margin-bottom: 40px;
    }

    &_card {
      padding: 3rem;
    }

    &_body {
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding: 1rem 0;
    }

    &_text {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      min-height: 12px;
      color: $color-ash;
      margin-bottom: .5rem;
    }

    &_amount {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      min-height: 24px;
      color: $color-secondary
    }

    &_count {
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      min-height: 24px;
      color: $color-black;
    }
  }

  &_progress {
    padding: 3rem;

    &_chart {
      // margin: 40px auto;
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .empty {
        width: 100%;
        max-width: 200px;
        min-height: 200px;
        border-radius: 50%;
        margin: 25px auto;
      }
    }

    &_target {
      margin: 1rem 0;

      span {
        min-height: 20px;
      }

      &::before {
        content: "\2022";
        color: $color-secondary;
        background-color: $color-secondary;
        font-weight: bold;
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-right: 10px;
      }

      &_current {
        &::before {
          color: $color-secondary-light;
          background-color: $color-secondary-light;
        }
      }
    }
  }

  .customtable {
    padding: 3rem;
    height: 100%;

    span {
      min-height: 20px;
      display: inline-block;
      width: 100%;

      &:empty {
        animation: loading 1.1s infinite linear;
        background: #dedfe1;
        border-radius: 5px;
        background-image: -webkit-linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
        background-image: linear-gradient(to right, #dedfe1 0%, #f2f3f5 20%, #dedfe1 40%, #dedfe1 100%);
        background-repeat: no-repeat;
      }
    }
  }
}
