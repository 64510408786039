@import '../abstract/variables';

.commentmodal{
    &_header{
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        color: $color-secondary;
        margin-bottom: 3rem;
    }
}


.modal{
    &_header{
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: $color-primary;
        margin-bottom: 2rem;
    }
}

.comments-area{
    textarea{
        background-color: #F8F8F8 !important;
        resize: none;
        font-size: $font-size-regular;
    }
}

.modal-content{
    border-radius: 2rem !important;
    padding: 2rem;

    .modal-body{
        padding: 1rem 2rem;
    }
}