// @import "~ng-zorro-antd/style/index.min.css"; /* Import base styles */
// @import "~ng-zorro-antd/progress/style/index.min.css";
// @import "~ng-zorro-antd/select/style/index.min.css";
// @import "~ng-zorro-antd/empty/style/index.min.css";
// @import "~ng-zorro-antd/notification/style/index.min.css";
// @import "~ng-zorro-antd/modal/style/index.min.css";
// @import "~ng-zorro-antd/input/style/index.min.css";
// @import "~ng-zorro-antd/popconfirm/style/index.min.css";
// @import "~ng-zorro-antd/tabs/style/index.min.css";

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}



virtual-scroller {
  width: 100%;
  height: 55vh;
}

/*user defined styles*/
@import "abstract/variables";


@import "base/typography";
@import "base/base";

@import "components/button";
@import "components/modal";
@import "components/table";

@import "layout/container";
@import "layout/scrollbar";
@import "layout/layout";

@import "pages/dashboard";

